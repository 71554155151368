<template>
  <v-container :fluid="true" class="synapsa-container">
    <v-row align="center" justify="center" class="mx-0">
      <v-col cols="12" class="px-2 py-2 synapsa-tabs">
        <v-btn
          v-for="tab in tabs"
          v-permission="tab.permissions"
          :key="tab.id"
          :text="tab.id !== activeTab"
          :color="tab.id === activeTab ? 'primary' : ''"
          :outlined="tab.id === activeTab"
          small
          class="text-capitalize synapsa-tab"
          :class="tab.id === activeTab ? 'elevation-1' : ''"
          @click="to(tab)"
        >
          {{ $t(tab.name) }}
          <div
            v-if="tab.id === 'rulesetAlert' && rulesetAlertsCount > 0"
            class="alert-count ml-2"
          >
            <span>{{ rulesetAlertsCount }}</span>
          </div>
          <div
            v-if="tab.id === 'cveAlert' && cveAlertsCount > 0"
            class="alert-count ml-2"
          >
            <span>{{ cveAlertsCount }}</span>
          </div>
          <div
            v-if="tab.id === 'integrityAlert' && integrityAlertsCount > 0"
            class="alert-count ml-2"
          >
            <span>{{ integrityAlertsCount }}</span>
          </div>
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="mx-0">
      <v-col cols="12" class="py-0 px-0">
        <router-view />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import permission from "@/directive/permission/permission.js";

export default {
  directives: { permission },
  data() {
    return {
      activeTab: "rulesetAlert",
      tabs: [
        {
          id: "rulesetAlert",
          name: "auditor.ruleset",
          route: "/monitor/auditor/ruleset/active",
          permissions: ["view monitorauditorruleset"],
        },
        {
          id: "cveAlert",
          name: "auditor.cve",
          route: "/monitor/auditor/cve/active",
          permissions: ["view monitorauditorcve"],
        },
        {
          id: "integrityAlert",
          name: "auditor.integrity",
          route: "/monitor/auditor/integrity/active",
          permissions: ["view monitorauditorintegrity"],
        },
      ],
    };
  },
  watch: {
    $route: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (
          [
            "rulesetAlertActive",
            "rulesetAlertConfirmed",
            "rulesetAlertFixed",
          ].includes(newValue.name)
        ) {
          this.activeTab = "rulesetAlert";
        } else if (
          ["cveAlertActive", "cveAlertConfirmed"].includes(newValue.name)
        ) {
          this.activeTab = "cveAlert";
        } else if (
          ["integrityAlertActive", "integrityAlertConfirmed"].includes(
            newValue.name
          )
        ) {
          this.activeTab = "integrityAlert";
        }
      },
    },
  },
  computed: {
    rulesetAlertsCount: {
      get() {
        return this.$store.state.synapsa.rulesetAlertsCount;
      },
    },
    cveAlertsCount: {
      get() {
        return this.$store.state.synapsa.cveAlertsCount;
      },
    },
    integrityAlertsCount: {
      get() {
        return this.$store.state.synapsa.integrityAlertsCount;
      },
    },
  },
  methods: {
    to(tab) {
      this.$router.push(tab.route);
      this.activeTab = tab.id;
    },
  },
};
</script>
<style lang="scss" scoped>
.processing-info {
  text-align: center;
  border-radius: 0 0 1rem 1rem;
  background-color: #ffd04e;
  color: #535452;
}
.alert-count {
  float: right;
  background: #e53355;
  color: #fff;
  padding: 0 5px;
  border-radius: 4px;
}
</style>
